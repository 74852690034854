<template>
    <div>
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
      >
  
        <!-- Personal details tab -->
        <tab-content
          title="Personal Info"
          :before-change="validationForm"
        >
          <validation-observer
            ref="accountRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Basic Details
                </h5>
                <small class="text-muted">
                  Enter Your Basic Details.
                </small>
              </b-col>
              <b-col md="4" sm="6">
                <b-form-group
                  label="Firstname"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="fisrtname"
                    rules="required|min:3"
                  >
                    <b-form-input
                      id="fisrtname"
                      v-model="member.name.first"
                      :state="errors.length > 0 ? false:null"
                      placeholder="john"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              
              <b-col md="4" sm="6">
                <b-form-group
                  label="Lastname"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="lastname"
                    rules="required|min:3"
                  >
                    <b-form-input
                      id="name"
                      v-model="member.name.last"
                      :state="errors.length > 0 ? false:null"
                      placeholder="doe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4" >
                <b-form-group
                  label="Othernames"
                  label-for="name"
                >

                    <b-form-input
                      id="othernames"
                      v-model="member.name.other"
                      placeholder="other"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4" sm="6"
                >
                <b-form-group
                    label="Gender"
                    label-for="gender"
                    label-class="mb-1"
                >
                    <b-form-radio-group
                    id="gender"
                    v-model="member.gender"
                    :options="genderOptions"
                    value="male"
                    />
                </b-form-group>
              </b-col>
              
              <b-col md="4"  sm="6">
                <b-row>
                    <b-col md="4" sm="4">
                        <b-form-group
                            label="Country"
                            label-for="country"
                            >
                                <vue-country-code
                                    class=" is-size-5"
                                    @onSelect="onSelect"
                                    v-model="member"
                                    :enabledCountryCode="true"
                                    :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                    >
                                </vue-country-code>
                        </b-form-group>
                    </b-col>
                    <b-col md="8" sm="8">
                        <b-form-group
                            label="Contact 1"
                            label-for="contact1"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="contact1"
                                    rules="required|min:9"
                                >
                                    <b-form-input
                                    id="contact1"
                                    v-model="member.contact1"
                                    placeholder="XXXXXXXXX"
                                    type="tel"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                    </b-col>
                </b-row>
              </b-col>

              <b-col md="4"  sm="6">
                <b-row>
                    <b-col md="4" sm="4">
                        <b-form-group
                            label="Country"
                            label-for="country"
                            >
                                <vue-country-code
                                    class=" is-size-5"
                                    @onSelect="onSelect2"
                                    :enabledCountryCode="true"
                                    :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                    >
                                </vue-country-code>
                        </b-form-group>
                    </b-col>
                    <b-col md="8" sm="8">
                        <b-form-group
                            label="Contact 2"
                            label-for="contact2"
                            >

                                <b-form-input
                                id="contact2"
                                v-model="member.contact2"
                                placeholder="XXXXXXXXX"
                                type="tel"
                                />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                            </b-form-group>
                    </b-col>
                </b-row>
              </b-col>

              <b-col ols="12" sm="6" lg="4"
                >
                <b-form-group
                    label="Language Options"
                    label-for="language-options"
                    label-class="mb-1"
                >
                    <b-form-checkbox-group
                    id="language-options"
                    v-model="member.languages"
                    :options="languageOptions"
                    />
                </b-form-group>
              </b-col>

              <b-col md="4" sm="6">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  > -->
                    <b-form-input
                      id="email"
                      v-model="member.email"
                      type="email"
                      
                      placeholder="john.doe@email.com"
                    />
                    <!-- :state="errors.length > 0 ? false:null" -->
                    <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                  <!-- </validation-provider> -->
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6" lg="4"
                >
                <b-form-group
                    label="Contact Options"
                    label-for="contact-options"
                    label-class="mb-1"
                >
                    <b-form-checkbox-group
                    id="contact-options"
                    v-model="member.contactOptions"
                    :options="contactOptionsOptions"
                    />
                </b-form-group>
              </b-col>
              
              <!-- <b-col md="6">
                <b-form-group
                  label="Password"
                  label-for="password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required|password"
                  >
                    <b-form-input
                      id="password"
                      v-model="PasswordValue"
                      type="password"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Confirm Password"
                  label-for="c-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password Confirm"
                    rules="required|confirmed:Password"
                  >
                    <b-form-input
                      id="c-password"
                      v-model="passwordCon"
                      type="password"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Re-type Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->

              
              
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="my-2"
              >
                <h5 class="mb-0">
                  Birth Details
                </h5>
                <small class="text-muted">
                  Enter Your Birth Details.
                </small>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label="Birth Date"
                    label-for="birth-date"
                >
                    <flat-pickr
                    v-model="member.dob"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d'}"
                    placeholder="YYYY-MM-DD"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Birth Place"
                  label-for="birth-place"
                >
                    <b-form-input
                      v-model="member.pob"
                      type="text"
                      placeholder="place of birth"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <!-- <validation-provider
                  #default="{ errors }"
                  name="dayborn"
                  rules="required"
                > -->
                  <b-form-group
                    label="Birth Day (Dayborn)"
                    label-for="dayborn"
                    
                  >
                  <!-- :state="errors.length > 0 ? false:null" -->
                    <v-select
                      id="dayborn"
                      v-model="member.dayborn"
                      :reduce="option => option.value"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="daybornOptions"
                      :selectable="option => ! option.value.includes('select_value')"
                      label="text"
                    />
                    <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback> -->
                  </b-form-group>
                <!-- </validation-provider> -->
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="my-2"
              >
                <h5 class="mb-0">
                  Work Details
                </h5>
                <small class="text-muted">
                  Enter Your Work Details.
                </small>
              </b-col>
              
              <b-col cols="12" md="4" sm="6"
                >
                <b-form-group
                    label="Work Status"
                    label-for="work-status"
                    label-class="mb-1"
                >
                    <b-form-radio-group
                    id="workstatus"
                    v-model="member.workStatus"
                    :options="workStatusOptions"
                    value="male"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label="Occupation"
                    label-for="occupation"
                >
                    <b-form-input
                     :disabled="member.workStatus == 'Unemployed'"
                      v-model="member.occupation"
                      type="text"
                      placeholder="occupation"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label="Work Place"
                    label-for="place-of-work"
                >
                    <b-form-input
                     :disabled="member.workStatus == 'Unemployed'"
                      v-model="member.pow"
                      type="text"
                      placeholder="place of work"
                    />
                </b-form-group>
              </b-col>
              
            </b-row>
          </validation-observer>
        </tab-content>
  
        <!-- contact details tab -->
        <tab-content
          title="Address & Contacts"
          :before-change="validationFormInfo"
        >
          <validation-observer
            ref="infoRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Address Info
                </h5>
                <small class="text-muted">Enter Your Address Info.</small>
              </b-col>
              
              <b-col md="4" sm="6">
                <b-form-group
                  label="Address (P.O.Box / GPS / Address)"
                  label-for="address"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="address"
                    rules="required|min:2"
                  >
                    <b-form-input
                      id="address"
                      v-model="member.addressName"
                      placeholder="address"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4" sm="6">
                <b-form-group
                  label="Residence (Name of Area)"
                  label-for="residence"
                >
                    <b-form-input
                      v-model="member.residence"
                      placeholder="residence"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4" sm="6">
                <b-form-group
                  label="GPS Coordinates (lat, lng)"
                  label-for="coordinates"
                >
                    <b-form-input
                      v-model="member.address"
                      placeholder="gps coordinates"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Nationality"
                  rules="required"
                >
                  <b-form-group
                    label="Nationality" 
                    label-for="nationality"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      v-model="member.nationality"
                      :options="nationOptions"
                      :reduce="option => option.value"
                      :selectable="option => ! option.value.includes('select_value')"
                      label="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4" sm="6"
                >
                <b-form-group
                    label="Is Father Alive"
                    label-for="is-father-alive"
                    label-class="mb-1"
                >
                    <b-form-radio-group
                    id="father"
                    v-model="member.isFatherAlive"
                    :options="booleanOptions"
                    />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="4" sm="6"
                >
                <b-form-group
                    label="Is Mother Alive"
                    label-for="is-mother-alive"
                    label-class="mb-1"
                >
                    <b-form-radio-group
                      id="mother"
                      v-model="member.isMotherAlive"
                      :options="booleanOptions"
                      :selectable="option => ! option.value.includes('select_value')"
                    />
                </b-form-group>
              </b-col>
              <!-- Emergency -->
              <b-col md="4" sm="6">
                <b-form-group
                  label="Home Town"
                  label-for="home-town"
                >
                    <b-form-input
                      id="home-town"
                      v-model="member.hometown"
                      placeholder="home town"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                  <b-form-group
                    label="Region"
                    label-for="region"
                    
                  >
                    <v-select
                      :reduce="option => option.value"
                      v-model="member.region"
                      :options="regionOptions"
                      label="text"
                    />
                  </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="District"
                  label-for="district"
                >
                    <b-form-input
                      v-model="member.district"
                      placeholder="District"
                    />
                </b-form-group>
              </b-col>
              
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="my-2"
              >
                <h5 class="mb-0">
                  Emergency Contact Info
                </h5>
                <small class="text-muted">Enter Your emergency contact Info. </small> <b-badge @click="duplicateEmg" variant="primary">Copy for Next of Kin </b-badge>
              </b-col>
              <b-col md="4" sm="6">
                <b-form-group
                  label="Emergency Contact Fullname"
                  label-for="emg-fname"
                >
                    <b-form-input
                      id="emg-fname"
                      v-model="member.emgFname"
                      placeholder="Emergency Contact Fullname"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-row>
                    <b-col md="4">
                        <b-form-group
                            label="Country"
                            label-for="country"
                            >
                                <vue-country-code
                                    class=" is-size-5"
                                    @onSelect="onSelect4"
                                    :enabledCountryCode="true"
                                    :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                    >
                                </vue-country-code>
                        </b-form-group>
                    </b-col>
                    <b-col md="8">
                        <b-form-group
                            label="Emergency Contact"
                            label-for="emg-contact"
                            >

                                <b-form-input
                                id="emg-contact"
                                v-model="member.emgCont"
                                placeholder="XXXXXXXXX"
                                type="tel"
                                />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                            </b-form-group>
                    </b-col>
                </b-row>
              </b-col>
              <b-col md="4">
                
                  <b-form-group
                    label="Emergency Contact Relationship"
                    label-for="nok-rel"
                  >
                    <v-select
                      :reduce="option => option.value"
                      v-model="member.emgRel"
                      :options="nokRelOptions"
                      :selectable="option => ! option.value.includes('select_value')"
                      label="text"
                    />
                  </b-form-group>
              </b-col>
              
            </b-row>
            <b-row>
              <b-col
                cols="12"
                class="my-2"
              >
                <h5 class="mb-0">
                  Next of Kin Info
                </h5>
                <small class="text-muted">Enter Your Next of Kin Info.</small>
              </b-col>
              
              <b-col md="4" sm="6">
                <b-form-group
                  label="Next of Kin Fullname"
                  label-for="nok-fname"
                >
                    <b-form-input
                      id="nok-fname"
                      v-model="member.nokFname"
                      placeholder="Next of Kin Fullname"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-row>
                    <b-col md="4">
                        <b-form-group
                            label="Country"
                            label-for="country"
                            >
                                <vue-country-code
                                    class=" is-size-5"
                                    @onSelect="onSelect3"
                                    :enabledCountryCode="true"
                                    :preferredCountries="[ 'us', 'gb', 'gh','ng','ke']"
                                    >
                                </vue-country-code>
                        </b-form-group>
                    </b-col>
                    <b-col md="8">
                        <b-form-group
                            label="Next of Kin Contact"
                            label-for="nok-contact"
                            >

                                <b-form-input
                                id="nok-contact"
                                v-model="member.nokCont"
                                placeholder="XXXXXXXXX"
                                type="tel"
                                />
                                <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                            </b-form-group>
                    </b-col>
                </b-row>
              </b-col>
              <b-col md="4">
                
                  <b-form-group
                    label="Next of Kin Relationship"
                    label-for="nok-rel"
                  >
                    <v-select
                      :reduce="option => option.value"
                      v-model="member.nokRel"
                      :options="nokRelOptions"
                      :selectable="option => ! option.value.includes('select_value')"
                      label="text"
                    />
                  </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
  
        <!-- address  -->
        <tab-content
          title="Marital Info"
          :before-change="validationFormAddress"
        >
          <validation-observer
            ref="addressRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Marital Info
                </h5>
                <small class="text-muted">Complete Your Marital Info.</small>
              </b-col>
              <b-col md="4">
                <validation-provider
                  #default="{ errors }"
                  name="Marital Status"
                  rules="required"
                >
                  <b-form-group
                    label="Marital Status" 
                    label-for="marital"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      v-model="member.maritalStatus"
                      :options="maritalOptions"
                      :selectable="option => ! option.value.includes('select_value')"
                      :reduce="option => option.value"
                      label="text"
                      @input="checkMarital"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Name Of Spouse"
                  label-for="nameofspouse"
                >
                    <b-form-input
                     :disabled="member.maritalStatus == 'single'"
                      id="nameOfSpouse"
                      v-model="member.nameOfSpouse"
                      placeholder="name of spouse"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Date Of Marriage"
                  label-for="dateofmarriage"
                >
                  <flat-pickr
                    v-model="member.dateOfMarriage"
                    :disabled="member.maritalStatus == 'single'"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d'}"
                    placeholder="YYYY-MM-DD"
                    />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                class="my-2"
              >
                <h5 class="mb-0">
                  Other Marital Info
                </h5>
                <small class="text-muted">Complete Your Other Marital Info.</small>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Place of Marriage"
                  label-for="placeofmarriage"
                >
                  <b-form-input
                    :disabled="member.maritalStatus == 'single'"
                    id="placeofmarriage"
                    v-model="member.placeOfMarriage"
                    placeholder="place of marriage"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                  <b-form-group
                    label="Type of Marriage" 
                    label-for="typeofmarital"
                  >
                    <v-select
                      :reduce="option => option.value"
                      id="typeofmarital"
                      :disabled="member.maritalStatus == 'single'"
                      v-model="member.typeOfMarriage"
                      :options="marriageOptions"
                      :selectable="option => ! option.value.includes('select_value')"
                      label="text"
                    />
                  </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Marital Note"
                  label-for="maritalnote"
                >
                    <b-form-input
                      id="maritalnote"
                      :disabled="member.maritalStatus == 'single'"
                      v-model="member.maritalnote"
                      placeholder="marital note"
                    />
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
  
        <!-- church info -->
        <tab-content
          title="Church Info"
          :before-change="validationFormSocial"
        >
          <validation-observer
            ref="socialRules"
            tag="form"
          >
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Church Info
                </h5>
                <small class="text-muted">Enter Church Info </small>
              </b-col>
              <b-col md="3">
                <validation-provider
                  #default="{ errors }"
                  name="Membership Type"
                  rules="required"
                >
                  <b-form-group
                    label="Membership Type" 
                    label-for="membership"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      :reduce="option => option.value"
                      v-model="member.membershipType"
                      :options="membershipOptions"
                      :selectable="option => ! option.value.includes('select_value')"
                      label="text"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="3">
                <!-- <validation-provider
                  #default="{ errors }"
                  name="Membership ID"
                  rules="required"
                > -->
                  <b-form-group
                      label="Membership ID"
                      label-for="membership-id"
                      >
                      <!-- :state="errors.length > 0 ? false:null" -->
                          <b-form-input
                          id="membership-id"
                          v-model="member.memID"
                          placeholder="membership ID"
                          />
                          <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                            {{ errors[0] }}
                          </b-form-invalid-feedback> -->
                          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                    </b-form-group>
                  <!-- </validation-provider> -->
                </b-col>
              <b-col md="3">
                <b-form-group
                  label="Date Joined"
                  label-for="datejoined"
                >
                  <flat-pickr
                    v-model="member.dateJoined"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d'}"
                    placeholder="YYYY-MM-DD"
                    />
                </b-form-group>
              </b-col>
              

              <b-col md="3">
                <b-form-group
                  label="Date Died"
                  label-for="date-died"
                >
                <flat-pickr
                    v-model="member.dateDied"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d'}"
                    placeholder="YYYY-MM-DD"
                    :disabled="member.membershipType !== 'deceased'"
                    />
                </b-form-group>
              </b-col>
              
              <b-col md="3">
                <b-form-group
                  label="Tithe Payer"
                  label-for="tithe"
                >
                  <b-form-radio-group
                    id="tithe"
                    v-model="member.tithePayer"
                    :options="booleanOptions"
                    
                  />
                </b-form-group>
              </b-col>


              <b-col md="3">
                    <b-form-group
                        label="Groups" 
                        label-for="groups"
                    >
                        <v-select
                        v-model="member.groups"
                        :options="allGroups.map(g=>({ group: g.group, church: g.church }))"
                        label="group"
                        multiple
                        />
                    </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group
                      label="Short Bio"
                      label-for="short-id"
                      >

                          <b-form-textarea
                          type="textarea"
                          id="short-id"
                          v-model="member.bio"
                          placeholder="Short Bio"
                          ></b-form-textarea>
                          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group
                      label="Other "
                      label-for="other-info"
                      >

                          <b-form-textarea
                          id="other-info"
                          v-model="member.other"
                          placeholder="Other Information"
                          ></b-form-textarea>
                          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                      </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <h5 class="mb-0">
                    Church Appointments <b-badge variant="primary" @click="addAppoint">Add</b-badge>
                  </h5>
                  <small class="text-muted">Enter Church appointment </small>
                </b-col>
                
                <b-col sm="12">
                  <b-row v-for="(appoint, index) in member.churchAppointments" :key="index">

                    <b-col md="3">
                        <b-form-group
                            label="Appointment" 
                            label-for="appointment"
                        >
                            <v-select
                            :reduce="option => option.appointment"
                            v-model="appoint.appointment"
                            :options="allAppointments.map(a=>({ appointment: a.appointment, church: a.church }))"
                            label="appointment"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group
                        label="From"
                        label-for="from"
                        >
                        <flat-pickr
                            v-model="appoint.from"
                            class="form-control"
                            :config="{ dateFormat: 'Y-m-d'}"
                            placeholder="YYYY-MM-DD"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col md="2">
                        <b-form-group
                        label="To"
                        label-for="to"
                        >
                        <flat-pickr
                            v-model="appoint.to"
                            class="form-control"
                            :config="{ dateFormat: 'Y-m-d'}"
                            placeholder="YYYY-MM-DD"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col md="3">
                      <b-form-group
                          label="Remarks "
                          label-for="remarks"
                          >

                              <b-form-textarea
                              id="other-info"
                              v-model="appoint.remarks"
                              placeholder="Appointment Remarks"
                              ></b-form-textarea>
                              <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                          </b-form-group>
                    </b-col>

                    <b-col md="1">
                        <b-form-group
                        label="Add"
                        label-for=""
                        >
                        <b-button
                          variant="primary"
                          @click="addAppoint"
                          >
                          <feather-icon
                            icon="PlusIcon"
                            size="16"
                            class="mr-0 mr-sm-10"
                          />
                        </b-button>
                        </b-form-group>
                    </b-col>
                    <b-col md="1">
                        <b-form-group
                        label="Remove"
                        label-for=""
                        >
                        <b-button
                          variant="danger"
                          @click="delAppoint(index)"
                          >
                          <feather-icon
                            icon="XIcon"
                            size="16"
                          />
                        </b-button>
                        </b-form-group>
                    </b-col>

                    </b-row>
                  </b-col>  
              
            <!-- </b-row>
            <b-row> -->
              <b-col
                cols="12"
                class="my-2"
              >
                <h5 class="mb-0">
                  Confirmation & Baptism Info
                </h5>
                <small class="text-muted">Enter Confirmation & Baptism Info </small>
              </b-col>
              

              <b-col md="3">
                <b-form-group
                  label="Communicant (The Lord's supper)"
                  label-for="communicant"
                >
                  <b-form-radio-group
                    id="communicant"
                    v-model="member.communicant"
                    :options="booleanOptions"
                    
                  />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Confirmation Date"
                >
                  <flat-pickr
                   :disabled="!member.communicant"
                    v-model="member.confirmationDate"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d'}"
                    placeholder="YYYY-MM-DD"
                    />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Baptism By"
                  label-for="baptismby"
                >
                    <b-form-input
                      id="baptismby"
                      :disabled="!member.communicant"
                      v-model="member.baptismBy"
                      placeholder="baptism by"
                    />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  label="Baptism Date"
                >
                  <flat-pickr
                   :disabled="!member.communicant"
                    v-model="member.baptismDate"
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d'}"
                    placeholder="YYYY-MM-DD"
                    />
                </b-form-group>
              </b-col>

            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
  
    </div>
  </template>
  
  <script>
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import vSelect from 'vue-select'
  import router from '@/router'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import flatPickr from 'vue-flatpickr-component'
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BBadge,
    BFormTextarea,
    BFormInvalidFeedback,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton
  } from 'bootstrap-vue'
  import { required, email } from '@validations'
  import { codeIcon } from './code'
  import axios from 'axios'
  import store from '@/store'
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      FormWizard,
      TabContent,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BBadge,
      BFormTextarea,
      vSelect,
      BFormInvalidFeedback,
      BFormRadioGroup,
      BFormCheckboxGroup,
      BFormCheckboxGroup,
      // eslint-disable-next-line vue/no-unused-components
      ToastificationContent,
      flatPickr,
      BButton
    },
    props: {
      allGroups: {
        type: Array,
        default: []
      },
      allAppointments: {
        type: Array,
        default: []
      }
    },
    data() {
      return {
        member: {
            name: {
                first: 'John',
                last: 'Doe',
                other: ''
            },
            gender: 'male',
            country: '',
            contact1: '240000001',
            contact2: '',
            email: '',
            dob: null,
            pob: '',
            dayborn: 'sunday',
            pow: '',
            occupation: '',
            workStatus: 'Employed',
            languages: [ 'English', 'Twi' ],
            hometown: '',
            region: 'Greater Accra',
            district: '',
            nationality: 'ghanaian',
            residence: '',
            nokFname: '',
            nokCountry: '',
            nokCont: '',
            nokRel: null,
            isFatherAlive: true,
            isMotherAlive: true,
            maritalStatus: 'single',
            nameOfSpouse: '',
            dateOfMarriage: null,
            placeOfMarriage: '',
            typeOfMarriage: null,
            emgFname: '',
            emgCountry: '',
            emgCont: '',
            emgRel: null,
            dateJoined: null,
            dateDied: null,
            membershipType: 'active',
            communicant: null,
            confirmationDate: null,
            baptismDate: null,
            baptismBy: null,
            tithePayer: null,
            groups: [],
            bio: '',
            other: '',
            addressName: 'address',
            address: '',
            contactOptions: ['Email', 'Message'],
            memID: '',
            churchAppointments: [],
        },
        isNotMarried: true,
        selectedContry: '',
        selectedLanguage: '',
        name: '',
        emailValue: '',
        PasswordValue: '',
        passwordCon: '',
        first_name: '',
        last_name: '',
        address: '',
        landMark: '',
        pincode: '',
        twitterUrl: '',
        facebookUrl: '',
        googleUrl: '',
        linkedinUrl: '',
        city: '',
        required,
        email,
        codeIcon,
        booleanOptions: [
          { value: true, text: 'Yes' },
          { value: false, text: 'No' }
        ],
        genderOptions: [
          { value: 'male', text: 'Male' },
          { value: 'female', text: 'Female' }
        ],
        daybornOptions: [
          { value: 'select_value', text: 'Select Value' },
          { value: 'sunday', text: 'Sunday' },
          { value: 'monday', text: 'Monday' },
          { value: 'tuesday', text: 'Tuesday' },
          { value: 'wednesday', text: 'Wednesday' },
          { value: 'thursday', text: 'Thursday' },
          { value: 'friday', text: 'Friday' },
          { value: 'saturday', text: 'Saturday' },
        ],
        maritalOptions: [
          { value: 'select_value', text: 'Select Value' },
          { value: 'single', text: 'Single' },
          { value: 'married', text: 'Married' },
          { value: 'seperated', text: 'Seperated' },
          { value: 'divorced', text: 'Divorced' },
          { value: 'widow / widower', text: 'Widow / Widower' },
        ],
        marriageOptions: [
          { value: 'select_value', text: 'Select Value' },
          { value: 'ordinance', text: 'Ordinance' },
          { value: 'blessed', text: 'Blessed' },
          { value: 'customary', text: 'Customary' },
        ],
        membershipOptions: [
          { value: 'select_value', text: 'Select Value' },
          { value: 'active', text: 'Active' },
          { value: 'not active', text: 'Not Active'  },
          { value: 'invalid', text: 'Invalid' },
          { value: 'distant', text: 'Distant' },
          { value: 'deceased', text: 'Deceased' },
        ],
        nationOptions: [
            { value: 'select_value', text: 'Select Value' },
            { value: 'ghanaian', text: 'Ghanaian' },    
            { value: 'nigerian', text: 'Nigerian' },    
            { value: 'ivorian', text: 'Ivorian' },    
            { value: 'togolese', text: 'Togolese' },    
            { value: 'burkinabe', text: 'Burkinabe' },
        ],
        nokRelOptions: [
            { value: 'select_value', text: 'Select Value' },
            { value: 'parent', text: 'Parent' },    
            { value: 'auntie/uncle', text: 'Auntie / Uncle' },    
            { value: 'child', text: 'Child' },    
            { value: 'sibling', text: 'Sibling' },    
            { value: 'cousin', text: 'Cousin' },
            { value: 'spouse', text: 'Spouse' },
            { value: 'friend', text: 'Friend' },
            { value: 'guardian', text: 'Guardian' },
            { value: 'other', text: 'Other' },
        ],
        countryName: [
          { value: 'select_value', text: 'Select Value' },
          { value: 'Russia', text: 'Russia' },
          { value: 'Canada', text: 'Canada' },
          { value: 'China', text: 'China' },
          { value: 'United States', text: 'United States' },
          { value: 'Brazil', text: 'Brazil' },
          { value: 'Australia', text: 'Australia' },
          { value: 'India', text: 'India' },
        ],
        regionOptions: ['select_value', 'Ahafo','Ashanti','Brong Ahafo','Bono East','Central','Eastern','Greater Accra','Northern','Upper East','Upper West','Volta','Western','Savannah','Oti','Western North','North East'],
        languageName: [
          { value: 'nothing_selected', text: 'Nothing Selected' },
          { value: 'English', text: 'English' },
          { value: 'Chinese', text: 'Mandarin Chinese' },
          { value: 'Hindi', text: 'Hindi' },
          { value: 'Spanish', text: 'Spanish' },
          { value: 'Arabic', text: 'Arabic' },
          { value: 'Malay', text: 'Malay' },
          { value: 'Russian', text: 'Russian' },
        ],
        contactOptionsOptions: ['Email', 'Message', 'Phone'],
        languageOptions: ['English', 'Twi', 'Ga', 'Dangme', 'Ewe', 'Hausa'],
        workStatusOptions: ['Employed', 'Unemployed', 'Self-employed', 'Retired' ],
        communicantOptions: ['Yes', 'No' ],
      }
    },
    methods: {
      duplicateEmg() {
      
        this.member.nokFname = this.member.emgFname
        this.member.nokCountry = this.member.emgCountry
        this.member.nokCont = this.member.emgCont
        this.member.nokRel = this.member.emgRel
      },
      addAppoint() {
        let appoint = {
          appointment: '',
          from: '',
          to: '',
          remarks: ''
        }

        if (typeof(this.member.churchAppointments) == "undefined") {

          this.member["churchAppointments"] = []
          this.member.churchAppointments.push(appoint)

        } else {
          this.member.churchAppointments.push(appoint)
        }

      },
      delAppoint(key) {
        this.member.churchAppointments.splice(key, 1)
      },
      checkMarital() {
        console.log(this.member.maritalStatus)
        // this.isNotMarried = this.member.maritalStatus == 'single' ? false : true
      },
      formSubmitted() {

        store.dispatch('app-member/addMember', this.member)
        .then(res=>{
          //
          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          
          // redirect after 2.5 secs
          setTimeout(function(){
            //
            router.push({ name: 'apps-members-list', params: { search: res.data.split(' to')[0].split('added ')[1] } })

          }, 2500)
          
        }).catch(err=>{
          //
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failed! '+err.response.data,
              icon: 'DeleteIcon',
              variant: 'danger',
            },
          })
        })

        //
        // axios.post('apps/member', this.member).then(res=>{
        //   //
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: 'Form Submitted! '+res.data,
        //       icon: 'EditIcon',
        //       variant: 'success',
        //     },
        //   })
        // }).catch(err=>{
        //   //
        //   this.$toast({
        //     component: ToastificationContent,
        //     props: {
        //       title: 'Failed! '+err.response.data,
        //       icon: 'DeleteIcon',
        //       variant: 'danger',
        //     },
        //   })
        // })
        
      },
      validationForm() {
        return new Promise((resolve, reject) => {
          this.$refs.accountRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      },
      validationFormInfo() {
        return new Promise((resolve, reject) => {
          this.$refs.infoRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      },
      validationFormAddress() {
        return new Promise((resolve, reject) => {
          this.$refs.addressRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      },
      validationFormSocial() {
        return new Promise((resolve, reject) => {
          this.$refs.socialRules.validate().then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
        })
      },
      onSelect({name, iso2, dialCode}) {
          this.member.country = "+"+dialCode
          console.log(name, iso2, dialCode);
      },
      onSelect2({name, iso2, dialCode}) {
          this.member.country1 = "+"+dialCode
          console.log(name, iso2, dialCode);
      },
      onSelect3({name, iso2, dialCode}) {
          this.member.nokCountry = "+"+dialCode
          console.log(name, iso2, dialCode);
      },
      onSelect4({name, iso2, dialCode}) {
          this.member.emgCountry = "+"+dialCode
          console.log(name, iso2, dialCode);
      }
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
    .vue-country-select  {
        width: 100%
    }

    .vue-country-select > .dropdown {
        padding: 0.6em !important;
    }
  </style>