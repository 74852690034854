import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAppointmentsList(data, resetMessage, isModalActive) {
  // Use toast
  const toast = useToast()

  const refAppointmentListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'appointment', sortable: true },
    { key: 'description', sortable: false },
    { key: 'members', sortable: true, formatter: val => `$${val}` },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalAppointments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('appointment')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refAppointmentListTable.value ? refAppointmentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalAppointments.value,
    }
  })

  const getAppointments = computed(() => {
    const response =  store.getters['app-appointment/getAppointments']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      status: statusFilter.value,
    })

    const { appointments, total } = response
    totalAppointments.value = total

    return appointments
  })

  const getAllAppointments = computed(() => {
    const response =  store.getters['app-appointment/getAllAppointments']
 
     const { appointments, total } = response
     totalAppointments.value = total
 
     return appointments
   })

  const refetchData = () => {
    refAppointmentListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const blankUserData = {
    appointment: '',
    from: '',
    to: '',
    description: '',
    members: [],
  }

  const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

  const clearUserData = () => {
    userData.value = JSON.parse(JSON.stringify(blankUserData))
  }

  const fetchAppointments = (ctx, callback) => {
    store
      .dispatch('app-appointment/fetchAppointments')
      .then(() => {
        // const { appointments, total } = response.data

        // callback(appointments)
        
        // totalAppointments.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching appointments' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchMembers = (ctx, callback) => {
    store
      .dispatch('app-member/fetchMembers')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching appointments list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  
  const onSubmit = () => {

    // console.log(data.value)

    const smsData = { 
                          contacts: [],
                          message: data.value.message,
                          appointments: [{ name: data.value.appointment, members: data.value.members }],
                          groups: [],
                          scheduled: data.value.schedule && data.value.time ? data.value.schedule+' '+data.value.time : null
                    }

    // init an sms for tithe and Thanksgivig
    store.dispatch('app-sms/addSms', { sms: smsData }).then(response => {
        // eslint-disable-next-line no-use-before-define
        toast({
          component: ToastificationContent,
          props: {
            title: response.data,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        resetMessage()
        isModalActive.value = false

      }).catch(error=> {
        
        toast({
            component: ToastificationContent,
            props: {
              title: error.response.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
  
          return error
      })
  }


  const editAppointment = (userEvent) => {
    
    userData.value = JSON.parse(JSON.stringify(Object.assign(userEvent)))
    // const { userData } = data
    // emit('update:is-add-new-user-sidebar-active', true)
    isAddNewMemberSidebarActive.value = true
    // console.log(userEvent)

    userData.value = grabDataFromEditAppointment(userEvent)

    if (userData.value.members.find(m => typeof(m.from) !== "undefined")) {
      let one = userData.value.members.find(m=>m.from && m.to)
      userData.value.from = one.from
      userData.value.to = one.to
    }
    
  }

  const grabDataFromEditAppointment = appointmentData => {

    const {
      id,
      appointment,
      description,
      members,
    } = appointmentData

    return {
      id,
      appointment,
      description,
      members,
    }
  }

  // ------------------------------------------------
  // addAppointment
  // ------------------------------------------------
  const addAppointment = eventData => {
    store.dispatch('app-appointment/addAppointment', { appointment: eventData }).then((response) => {
      // eslint-disable-next-line no-use-before-define
      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })

      fetchAppointments()
    })
  }

  // ------------------------------------------------
  // updateAppointment
  // ------------------------------------------------
  const updateAppointment = eventData => {
    
    store.dispatch('app-appointment/updateAppointment', { appointment: eventData }).then((response) => {

      toast({
        component: ToastificationContent,
        props: {
          title: response.data,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      
      fetchAppointments()
    })
  }

  const delAppointment = eventData => {
    store.dispatch('app-appointment/delAppointment', { appointment: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      fetchAppointments()
    })
  }

  // const onSubmit = () => {

  //   if (userData.value.id) { 

  //     store.dispatch('app-appointment/updateAppointment', { appointment: userData.value })
  //     .then(() => {
  //       // emit('refetch-data')
  //       // props.isAddNewUserSidebarActive = true
  //       // emit('update:is-add-new-user-sidebar-active', false)
  //     })

  //   } else { 
  //     store.dispatch('app-appointment/addAppointment', { appointment: userData.value })
  //     .then(() => {
  //       // emit('refetch-data')
  //       // props.isAddNewUserSidebarActive = true
  //       // emit('update:is-add-new-user-sidebar-active', false)
  //     })
  //   }
    
  // }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAppointmentStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }

  const isAddNewMemberSidebarActive = ref(false)

  return {
    // onSubmit,
    getAppointments,
    fetchAppointments,
    fetchMembers,
    editAppointment,
    delAppointment,
    tableColumns,
    perPage,
    currentPage,
    totalAppointments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refAppointmentListTable,
    grabDataFromEditAppointment,
    isAddNewMemberSidebarActive,

    statusFilter,

    resolveAppointmentStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
    clearUserData,
    userData,
    addAppointment,
    updateAppointment,
    getAllAppointments,
    onSubmit
  }
}
