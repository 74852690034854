import axios from '@/libs/axios'
// '@/libs/axios'
const userData = JSON.parse(localStorage.getItem('userData'))
import { paginateArray, sortCompare } from '@/@fake-db/utils'

export default {
  namespaced: true,
  state: {
    appointments: [],
    total: 0,
  },
  getters: {
    getAppointments: (state) => (params) => {

      const { q = '', perPage = 10, page = 1, sortBy = 'appointment', sortDesc = false, status = null } = params

      const queryLowered = q.toLowerCase()
      const filteredData = state.appointments.filter(
        appointment =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (appointment.appointment.toLowerCase().includes(queryLowered) ||
          appointment.mission.toLowerCase().includes(queryLowered)) 
          // &&
          // appointment.invoiceStatus === (status || invoice.invoiceStatus),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      return {
        appointments: paginateArray(sortedData, perPage, page),
        total: filteredData.length,
      }
    },
    getAllAppointments: state => {
      return {
        appointments: state.appointments,
        total: state.appointments.length,
      }
    }
  },
  mutations: {
    updateAppointments:  (state, appointments) => {
      //
      state.appointments = appointments
      state.total = appointments.length
    },
    updateAppointment: (state, appointment) => {
      //
      state.appointment = appointment
    }
  },
  actions: {
    fetchAppointments({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/appointments/${userData.church}`)
          .then(response => { 
            
            //
            commit('updateAppointments', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    fetchAppointment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/appointment/${userData.church}/${id}`)
          .then(response => { 

            //
            commit('updateAppointment', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    addAppointment(ctx, { appointment }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/appointment', { ...appointment, church: userData.church })
          // .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAppointment(ctx, { appointment }) {
      // console.log(appointment)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/appointment/${userData.church}/${appointment.id}`, { ...appointment })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delAppointment(ctx, { appointment }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/appointment/${userData.church}/${appointment.id}/${appointment.appointment}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
