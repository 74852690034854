<template>
  <b-row>
    <b-col cols="12">
  <!-- <component :is="'b-card'"> -->

    <!-- Alert: No item found -->
    <!-- <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          Member List
        </b-link>
        for other users.
      </div>
    </b-alert> -->
    <member-add-wizard :allGroups="getAllGroups" :allAppointments="getAllAppointments" class="mt-2 pt-75" />
    <!-- <b-tabs
      pills
    > -->

      <!-- Tab: Account -->
      <!-- <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <member-add-tab-account
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab> -->

      <!-- Tab: Information -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <member-add-tab-information class="mt-2 pt-75" />
      </b-tab> -->

      <!-- Tab: Social -->
      <!-- <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Social</span>
        </template>
        <member-add-tab-social class="mt-2 pt-75" />
      </b-tab>
    </b-tabs> -->
  <!-- </component> -->
    </b-col>
  </b-row>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BRow, BCol
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import memberStoreModule from '../memberStoreModule'
import MemberAddWizard from './MemberAddWizard.vue'
import useGroupsList from '../../groups/group-list/useGroupList'
import groupStoreModule from '../../groups/groupStoreModule'
import useAppointmentList from '../../appointment/appointment-list/useAppointmentList'
import appointmentStoreModule from '../../appointment/appointmentStoreModule'

export default {
  components: {
    BRow, 
    BCol,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    MemberAddWizard,
  },
  setup() {

    const userData = ref(null)

    const blankUserData = {
      name: {
        first: '',
        last: '',
        other: '' 
      },
      fullName: 'Firstname Lastname',
      username: 'adads',
      email: 'adads',
      role: null,
      currentPlan: null,
      company: 'adad',
      country: 'ada',
      contact: 'ada',
      avatar: null,
    }

    userData.value = JSON.parse(JSON.stringify(blankUserData))

    const MEMBER_APP_STORE_MODULE_NAME = 'app-member'
    const GROUP_APP_STORE_MODULE_NAME = 'app-group'
    const APPOINTMENT_APP_STORE_MODULE_NAME = 'app-appointment'

    // Register module
    if (!store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.registerModule(MEMBER_APP_STORE_MODULE_NAME, memberStoreModule)
    if (!store.hasModule(GROUP_APP_STORE_MODULE_NAME)) store.registerModule(GROUP_APP_STORE_MODULE_NAME, groupStoreModule)
    if (!store.hasModule(APPOINTMENT_APP_STORE_MODULE_NAME)) store.registerModule(APPOINTMENT_APP_STORE_MODULE_NAME, appointmentStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MEMBER_APP_STORE_MODULE_NAME)) store.unregisterModule(MEMBER_APP_STORE_MODULE_NAME)
      if (store.hasModule(GROUP_APP_STORE_MODULE_NAME)) store.unregisterModule(GROUP_APP_STORE_MODULE_NAME)
      if (store.hasModule(APPOINTMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(APPOINTMENT_APP_STORE_MODULE_NAME)
    })

    const { fetchGroups, getAllGroups } = useGroupsList()
    const { fetchAppointments, getAllAppointments } = useAppointmentList()

    fetchAppointments()

    fetchGroups()

    // store.dispatch('app-member/fetchMember', { id: router.currentRoute.params.id })
    //   .then(response => { userData.value = response.data })
    //   .catch(error => {
    //     if (error.response.status === 404) {
    //       userData.value = undefined
    //     }
    //   })

    return {
      userData,
      getAllGroups,
      getAllAppointments
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
