import axios from '@/libs/axios'
// '@/libs/axios'
const userData = JSON.parse(localStorage.getItem('userData'))
import { paginateArray, sortCompare } from '@/@fake-db/utils'

export default {
  namespaced: true,
  state: {
    groups: [],
    total: 0,
  },
  getters: {
    getGroups: (state) => (params) => {

      const { q = '', perPage = 10, page = 1, sortBy = 'group', sortDesc = false, status = null } = params

      const queryLowered = q.toLowerCase()
      const filteredData = state.groups.filter(
        group =>
          /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
          (group.group.toLowerCase().includes(queryLowered) ||
          group.mission.toLowerCase().includes(queryLowered)) 
          // &&
          // group.invoiceStatus === (status || invoice.invoiceStatus),
      )
      /* eslint-enable  */

      const sortedData = filteredData.sort(sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      return {
        groups: paginateArray(sortedData, perPage, page),
        total: filteredData.length,
      }
    },
    getAllGroups: state => {
      return {
        groups: state.groups,
        total: state.groups.length,
      }
    }
  },
  mutations: {
    updateGroups:  (state, groups) => {
      //
      state.groups = groups
      state.total = groups.length
    },
    updateGroup: (state, group) => {
      //
      state.group = group
    }
  },
  actions: {
    fetchGroups({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/groups/${userData.church}`)
          .then(response => { 
            
            //
            commit('updateGroups', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    fetchGroup(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/group/${userData.church}/${id}`)
          .then(response => { 

            //
            commit('updateGroup', response.data)
            resolve(response)
          
          })
          .catch(error => reject(error))
      })
    },
    addGroup(ctx, { group }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/group', { ...group, church: userData.church })
          // .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateGroup(ctx, { group }) {
      // console.log(group)
      return new Promise((resolve, reject) => {
        axios
          .patch(`/group/${userData.church}/${group.id}`, { ...group })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delGroup(ctx, { group }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/group/${userData.church}/${group.id}/${group.group}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
