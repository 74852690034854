import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useGroupsList(data, resetMessage, isModalActive) {

  // const messageLocal = ref(JSON.parse(JSON.stringify(data.value)))

  // Use toast
  const toast = useToast()

  const refGroupListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'group', sortable: true },
    { key: 'mission', sortable: false },
    { key: 'description', sortable: false },
    { key: 'members', sortable: true, formatter: val => `$${val}` },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalGroups = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('group')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refGroupListTable.value ? refGroupListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalGroups.value,
    }
  })

  const getGroups = computed(() => {
    const response =  store.getters['app-group/getGroups']({
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      status: statusFilter.value,
    })

    const { groups, total } = response
    totalGroups.value = total

    return groups
  })

  const getAllGroups = computed(() => {
    const response =  store.getters['app-group/getAllGroups']
 
     const { groups, total } = response
     totalGroups.value = total
 
     return groups
   })

  const refetchData = () => {
    refGroupListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const blankUserData = {
    group: '',
    mission: '',
    description: '',
    members: [],
  }

  const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

  const clearUserData = () => {
    userData.value = JSON.parse(JSON.stringify(blankUserData))
  }

  const fetchGroups = (ctx, callback) => {
    store
      .dispatch('app-group/fetchGroups')
      .then(() => {
        // const { groups, total } = response.data

        // callback(groups)
        
        // totalGroups.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching groups' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchMembers = (ctx, callback) => {
    store
      .dispatch('app-member/fetchMembers')
      .then(response => {
        //
        
      })
      .catch((err) => {
        console.log(err)
        // toast({
        //   component: ToastificationContent,
        //   props: {
        //     title: 'Error fetching groups list',
        //     icon: 'AlertTriangleIcon',
        //     variant: 'danger',
        //   },
        // })
      })
  }

  const editGroup = (userEvent) => {
    
    userData.value = JSON.parse(JSON.stringify(Object.assign(userEvent)))
    // const { userData } = data
    // emit('update:is-add-new-user-sidebar-active', true)
    isAddNewMemberSidebarActive.value = true
    // console.log(userEvent)

    userData.value = grabDataFromEditGroup(userEvent)
  }

  const grabDataFromEditGroup = groupData => {

    const {
      id,
      group,
      mission,
      description,
      members,
    } = groupData

    return {
      id,
      group,
      mission,
      description,
      members,
    }
  }

  // ------------------------------------------------
  // addGroup
  // ------------------------------------------------
  const addGroup = eventData => {
    store.dispatch('app-group/addGroup', { group: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      fetchGroups()
    })
  }

  // ------------------------------------------------
  // updateGroup
  // ------------------------------------------------
  const updateGroup = eventData => {
    
    store.dispatch('app-group/updateGroup', { group: eventData }).then(() => {
      
      fetchGroups()
    })
  }

  const delGroup = eventData => {
    store.dispatch('app-group/delGroup', { group: eventData }).then(() => {
      // eslint-disable-next-line no-use-before-define
      fetchGroups()
    })
  }

  const onSubmit = () => {

    // console.log(data.value)

    const smsData = { 
                          contacts: [],
                          message: data.value.message,
                          groups: [{ name: data.value.group, members: data.value.members }],
                          appoinments: [],
                          scheduled: data.value.schedule && data.value.time ? data.value.schedule+' '+data.value.time : null
                    }

    // init an sms for tithe and Thanksgivig
    store.dispatch('app-sms/addSms', { sms: smsData }).then(response => {
        // eslint-disable-next-line no-use-before-define
        toast({
          component: ToastificationContent,
          props: {
            title: response.data,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        resetMessage()
        isModalActive.value = false

      }).catch(error=> {
        
        toast({
            component: ToastificationContent,
            props: {
              title: error.response.data,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
  
          return error
      })
  }

  // const onSubmit = () => {

  //   if (userData.value.id) { 

  //     store.dispatch('app-group/updateGroup', { group: userData.value })
  //     .then(() => {
  //       // emit('refetch-data')
  //       // props.isAddNewUserSidebarActive = true
  //       // emit('update:is-add-new-user-sidebar-active', false)
  //     })

  //   } else { 
  //     store.dispatch('app-group/addGroup', { group: userData.value })
  //     .then(() => {
  //       // emit('refetch-data')
  //       // props.isAddNewUserSidebarActive = true
  //       // emit('update:is-add-new-user-sidebar-active', false)
  //     })
  //   }
    
  // }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveGroupStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }

  const isAddNewMemberSidebarActive = ref(false)

  return {
    // onSubmit,
    getGroups,
    fetchGroups,
    fetchMembers,
    editGroup,
    delGroup,
    tableColumns,
    perPage,
    currentPage,
    totalGroups,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refGroupListTable,
    grabDataFromEditGroup,
    isAddNewMemberSidebarActive,

    statusFilter,

    resolveGroupStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
    clearUserData,
    userData,
    addGroup,
    updateGroup,
    getAllGroups,
    onSubmit
  }
}
